export let propList = [
  { prop: 'id',label: 'ID', align: 'center', width: '80', webId: 1, fixed: 'left', },
  { prop: 'cover_path', label: '封面', align: 'center', minWidth: '160', slotName: 'coverSlot', showOverflowTooltip: true, webId: 2, },
  { prop: 'type', label: '类型', align: 'center', minWidth: '100', slotName: 'typeSlot', showOverflowTooltip: true, webId: 3, },
  { prop: 'title_cn',label: '标题(中文)', align: 'center', minWidth: '100', showOverflowTooltip: true, copy: true, webId: 4 },
  { prop: 'title_us',label: '标题(英文)', align: 'center', showOverflowTooltip: true, minWidth: '100', copy: true, webId: 5 },
  { prop: 'subhead_cn',label: '副标题(中文)', align: 'center', minWidth: '100', showOverflowTooltip: true, copy: true, webId: 6 },
  { prop: 'subhead_us',label: '副标题(英文)', align: 'center', showOverflowTooltip: true, minWidth: '100', copy: true, webId: 7 },
  { prop: 'create_time', label: '创建时间', minWidth: '140', align: 'center', dateFormat: 'dateTime', showOverflowTooltip: true, webId: 8 },
  { prop: 'update_time', label: '更新时间', minWidth: '140', align: 'center', dateFormat: 'dateTime', showOverflowTooltip: true, webId: 9 },
]
export let operationProps = {
  isShow: true, //设置后面的选择选择条例出不出现
  checkedCities: [1, 2, 3, 4, 5, 6, 7, 8, 9], //配置默认出现的条例对应上方的webId
  checkeList: [
    //配置有哪些条咧id对应上方的webId
    { id: 1, label: 'ID' },
    { id: 2, label: '封面' },
    { id: 3, label: '类型' },
    { id: 4, label: '标题(中文)' },
    { id: 5, label: '标题(英文)' },
    { id: 6, label: '副标题(中文)' },
    { id: 7, label: '副标题(英文)' },
    { id: 8, label: '创建时间' },
    { id: 9, label: '更新时间' },
  ],
  width: 80, //配置选择栏的样式 宽带是多少
  align: 'center' //字体居中
}
export let otherProps = {
  showSelectColumn: false, //是否显示多选框
  showFooter: false //显示底部分页
}
