<template>
  <div>
    <el-dialog title="关联案例" :visible.sync="params.show" :before-close="close" size="80%" :with-header="false">
      <el-row>
        <el-col :span="24">
          <el-button class="tab-add-button" type="primary" @click="addClick">添加关联</el-button>
        </el-col>
      </el-row>
      <table-ui
        :propList="propList"
        :operationProps="operationProps"
        :listData="params.view"
        v-bind="otherProps"
      >
        <template #coverSlot="scope">
          <el-image slot="error" :src="scope.row.cover_path" >
            <i class="el-icon-picture-outline"></i>
          </el-image>
        </template>
        <template #operation="scope">
          <el-popconfirm title="确定删除？" @confirm="deleteClick(scope.row.id)">
            <span slot="reference" style="color: red;cursor: pointer;">删除</span>
          </el-popconfirm>
        </template>
      </table-ui>
    </el-dialog>
  </div>
</template>
<script>
import { editSolution } from '@/api/solution/solution'
import { propList, operationProps, otherProps } from '../config/industryConfig.js'
import TableUi from '@/components/table-ui/table-ui.vue'
export default {
  components: {
    TableUi
  },
  data() {
    return {
      propList,
      operationProps,
      otherProps,
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    // 刷新
    refreshClick () {
      this.$emit('refresh')
    },
    // 删除
    async deleteClick(id) {
      const ids = this.params.view.map(v => v.id)
      const index = ids.findIndex(v => v === id)
      if (index !== -1) {
        ids.splice(index, 1)
      }
      const result = await editSolution({
        id: this.params.id,
        company_case: ids.map(v => {
          return {
            id: v
          }
        })
      })
      if (result.success) {
        this.$message.success('删除成功')
        this.refreshClick()
      }
    },
    addClick () {
      this.$router.push({
        path: '/main/industry',
        query: {
          solution_id: this.params.id
        }
      })
    }
  },
  computed: {
    
  },
}
</script>
<style scoped lang="less">

</style>
