<template>
  <div>
    <el-dialog :title="this.params.data ? '编辑' : '添加'" :visible.sync="params.show"  fullscreen :before-close="close" size="60%" :with-header="false" @open="openDialog">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
        <el-form-item label="类型" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择">
            <el-option
              v-for="item in typeValue"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封面" prop="cover_path">
          <image-upload v-model="ruleForm.cover_path"></image-upload>
        </el-form-item>

        <el-form-item label="路由地址" prop="route">
          <el-select v-model="ruleForm.route" placeholder="请选择">
            <el-option
              v-for="item in routeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item  label="标题(中文)" prop="title_cn">
          <el-input v-model="ruleForm.title_cn" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item  label="标题(英文)" prop="title_us">
          <el-input v-model="ruleForm.title_us" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item  label="副标题(中文)" prop="subhead_cn">
          <el-input v-model="ruleForm.subhead_cn" placeholder="请输入副标题"></el-input>
        </el-form-item>
        <el-form-item  label="副标题(英文)" prop="subhead_us">
          <el-input v-model="ruleForm.subhead_us" placeholder="请输入副标题"></el-input>
        </el-form-item>

        
        <el-form ref="bannerRef" :model="ruleForm.document.banner" :rules="itemRules" label-width="120px">
          <el-form-item label="详情页banner图" prop="image_path">
            <image-upload v-model="ruleForm.document.banner.image_path"></image-upload>
          </el-form-item>
        </el-form>

        <div>
          <h3>概述</h3>
          <el-form ref="generalizeRef" :model="ruleForm.document.generalize" :rules="itemRules" label-width="120px">
            <el-form-item  label="标题(中文)" prop="title_cn">
              <el-input v-model="ruleForm.document.generalize.title_cn" placeholder="请输入标题(中文)"></el-input>
            </el-form-item>
            <el-form-item  label="标题(英文)" prop="title_us">
              <el-input v-model="ruleForm.document.generalize.title_us" placeholder="请输入标题(英文)"></el-input>
            </el-form-item>
            <el-form-item  label="描述(中文)" prop="describe_cn">
              <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" v-model="ruleForm.document.generalize.describe_cn" placeholder="请输入描述(中文)"></el-input>
            </el-form-item>
            <el-form-item  label="描述(英文)" prop="describe_us">
              <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" v-model="ruleForm.document.generalize.describe_us" placeholder="请输入描述(英文)"></el-input>
            </el-form-item>
            <el-form-item label="路由地址" prop="route">
              <el-input v-model="ruleForm.document.generalize.route" placeholder="请输入路由地址"></el-input>
            </el-form-item>
            <el-form-item  label="路由名称(中文)" prop="router_name_cn">
              <el-input v-model="ruleForm.document.generalize.router_name_cn" placeholder="请输入路由名称(中文)"></el-input>
            </el-form-item>
            <el-form-item  label="路由名称(英文)" prop="router_name_us">
              <el-input v-model="ruleForm.document.generalize.router_name_us" placeholder="请输入路由名称(英文)"></el-input>
            </el-form-item>
          </el-form>
        </div>

        <div>
          <h3>优点</h3>
          <div v-for="(item, index) in ruleForm.document.merit" :key="index">
            <h4>表单{{ index + 1 }}<el-link type="danger" @click="deleteMeritForm(index)">删除</el-link></h4>
            <el-form :ref="'meritRef' + index" :model="item" :rules="itemRules" label-width="120px">
              <el-form-item  label="标题(中文)" prop="title_cn">
                <el-input v-model="item.title_cn" placeholder="请输入标题(中文)"></el-input>
              </el-form-item>
              <el-form-item  label="标题(英文)" prop="title_us">
                <el-input v-model="item.title_us" placeholder="请输入标题(英文)"></el-input>
              </el-form-item>
              <el-form-item  label="描述(中文)" prop="describe_cn">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" v-model="item.describe_cn" placeholder="请输入描述(中文)"></el-input>
              </el-form-item>
              <el-form-item  label="描述(英文)" prop="describe_us">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" v-model="item.describe_us" placeholder="请输入描述(英文)"></el-input>
              </el-form-item>
              <el-form-item label="路由地址" prop="route">
                <el-input v-model="item.route" placeholder="请输入路由地址"></el-input>
              </el-form-item>
              <el-form-item  label="路由名称(中文)" prop="router_name_cn">
                <el-input v-model="item.router_name_cn" placeholder="请输入路由名称(中文)"></el-input>
              </el-form-item>
              <el-form-item  label="路由名称(英文)" prop="router_name_us">
                <el-input v-model="item.router_name_us" placeholder="请输入路由名称(英文)"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <el-link type="primary" @click="addMeritForm">添加</el-link>
        </div>

        <div>
          <h3>解决方案</h3>
          <div v-for="(item, index) in ruleForm.document.scheme" :key="index">
            <h4>表单{{ index + 1 }} <el-link type="danger" @click="deleteSchemeForm(index)">删除</el-link></h4>
            <el-form :ref="'schemeRef' + index" :model="item" :rules="itemRules" label-width="120px">
              <el-form-item  label="标题(中文)" prop="title_cn">
                <el-input v-model="item.title_cn" placeholder="请输入标题(中文)"></el-input>
              </el-form-item>
              <el-form-item  label="标题(英文)" prop="title_us">
                <el-input v-model="item.title_us" placeholder="请输入标题(英文)"></el-input>
              </el-form-item>
              <div v-for="(item2, index2) in item.children" :key="index2">
                <h5>表单{{ index + 1 }}-{{ index2 + 1 }} <el-link type="danger" @click="deleteSchemeItemForm(index, index2)">删除</el-link></h5>
                <el-form :ref="'schemeItemRef' + index" :model="item2" :rules="itemRules" label-width="120px">
                  <el-form-item label="封面" prop="cover_path">
                    <image-upload v-model="item2.cover_path"></image-upload>
                  </el-form-item>
                  <el-form-item  label="方案标题(中文)" prop="title_cn">
                    <el-input v-model="item2.title_cn" placeholder="请输入方案标题(中文)"></el-input>
                  </el-form-item>
                  <el-form-item  label="方案标题(英文)" prop="title_us">
                    <el-input v-model="item2.title_us" placeholder="请输入方案标题(英文)"></el-input>
                  </el-form-item>
                  <el-form-item  label="描述(中文)" prop="describe_cn">
                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" v-model="item2.describe_cn" placeholder="请输入描述(中文)"></el-input>
                  </el-form-item>
                  <el-form-item  label="描述(英文)" prop="describe_us">
                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" v-model="item2.describe_us" placeholder="请输入描述(英文)"></el-input>
                  </el-form-item>
                  <el-form-item label="路由地址" prop="route">
                    <el-input v-model="item2.route" placeholder="请输入路由地址"></el-input>
                  </el-form-item>
                  <el-form-item  label="路由名称(中文)" prop="router_name_cn">
                    <el-input v-model="item2.router_name_cn" placeholder="请输入路由名称(中文)"></el-input>
                  </el-form-item>
                  <el-form-item  label="路由名称(英文)" prop="router_name_us">
                    <el-input v-model="item2.router_name_us" placeholder="请输入路由名称(英文)"></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <el-link type="primary" @click="addSchemeItemForm(index)">添加方案</el-link>
            </el-form>
          </div>
          <el-link type="primary" @click="addSchemeForm">添加</el-link>
        </div>
        <div>
          <h3>资源</h3>
          <div v-for="(item, index) in ruleForm.document.resource" :key="index">
            <h4>表单{{ index + 1 }} <el-link type="danger" @click="deleteResourceForm(index)">删除</el-link></h4>
            <el-form :ref="'resourceRef' + index" :model="item" :rules="itemRules" label-width="120px">
              <el-form-item  label="标题(中文)" prop="title_cn">
                <el-input v-model="item.title_cn" placeholder="请输入标题(中文)"></el-input>
              </el-form-item>
              <el-form-item  label="标题(英文)" prop="title_us">
                <el-input v-model="item.title_us" placeholder="请输入标题(英文)"></el-input>
              </el-form-item>
              <el-form-item  label="描述(中文)" prop="describe_cn">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" v-model="item.describe_cn" placeholder="请输入描述(中文)"></el-input>
              </el-form-item>
              <el-form-item  label="描述(英文)" prop="describe_us">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" v-model="item.describe_us" placeholder="请输入描述(英文)"></el-input>
              </el-form-item>
              <el-form-item label="路由地址" prop="route">
                <el-input v-model="item.route" placeholder="请输入路由地址"></el-input>
              </el-form-item>
              <el-form-item  label="路由名称(中文)" prop="router_name_cn">
                <el-input v-model="item.router_name_cn" placeholder="请输入路由名称(中文)"></el-input>
              </el-form-item>
              <el-form-item  label="路由名称(英文)" prop="router_name_us">
                <el-input v-model="item.router_name_us" placeholder="请输入路由名称(英文)"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <el-link type="primary" @click="addResourceForm">添加</el-link>
        </div>

        <div>
          <h3>后续步骤</h3>
          <div v-for="(item, index) in ruleForm.document.follow_up" :key="index">
            <h4>表单{{ index + 1 }} <el-link type="danger" @click="deleteFollowUpForm(index)">删除</el-link></h4>
            <el-form :ref="'followUpRef' + index" :model="item" :rules="itemRules" label-width="120px">
              <el-form-item  label="标题(中文)" prop="title_cn">
                <el-input v-model="item.title_cn" placeholder="请输入标题(中文)"></el-input>
              </el-form-item>
              <el-form-item  label="标题(英文)" prop="title_us">
                <el-input v-model="item.title_us" placeholder="请输入标题(英文)"></el-input>
              </el-form-item>
              <el-form-item  label="描述(中文)" prop="describe_cn">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" v-model="item.describe_cn" placeholder="请输入描述(中文)"></el-input>
              </el-form-item>
              <el-form-item  label="描述(英文)" prop="describe_us">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8}" v-model="item.describe_us" placeholder="请输入描述(英文)"></el-input>
              </el-form-item>
              <el-form-item label="路由地址" prop="route">
                <el-input v-model="item.route" placeholder="请输入路由地址"></el-input>
              </el-form-item>
              <el-form-item  label="路由名称(中文)" prop="router_name_cn">
                <el-input v-model="item.router_name_cn" placeholder="请输入路由名称(中文)"></el-input>
              </el-form-item>
              <el-form-item  label="路由名称(英文)" prop="router_name_us">
                <el-input v-model="item.router_name_us" placeholder="请输入路由名称(英文)"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <el-link type="primary" @click="addFollowUpForm">添加</el-link>
        </div>


        <!-- <el-form-item  label="方案介绍(中文)" prop="introduce_cn">
          <tinymce v-model="ruleForm.introduce_cn"></tinymce>
        </el-form-item>
        <el-form-item  label="方案介绍(英文)" prop="introduce_us">
          <tinymce v-model="ruleForm.introduce_us"></tinymce>
        </el-form-item>

        <el-form-item  label="方案架构(中文)" prop="architecture_cn">
          <tinymce v-model="ruleForm.architecture_cn"></tinymce>
        </el-form-item>
        <el-form-item  label="方案架构(英文)" prop="architecture_us">
          <tinymce v-model="ruleForm.architecture_us"></tinymce>
        </el-form-item> -->

        <el-form-item>
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { editSolution, addSolution } from '@/api/solution/solution'
import tinymce from '@/components/common/tinymce.vue'
import imageUpload from '@/components/imageupload/image-upload.vue'
export default {
  components: {
    tinymce,
    imageUpload
  },
  data() {
    return {
      typeValue: [
        {
          label: '混合云解决方案',
          value: 1
        },
        {
          label: '网站解决方案',
          value: 2
        },
        {
          label: '行业解决方案',
          value: 3
        },
        {
          label: '工程解决方案',
          value: 4
        }
      ],
      routeList: [
        {
          label: '方案模板一',
          value: '/introduce/engineering/solution/v2'
        },
      ],
      ruleForm: {
        id: null,
        type: 1,
        title_cn: '',
        title_us: '',
        subhead_cn: '',
        subhead_us: '',
        cover_path: '',
        introduce_cn: '',
        introduce_us: '',
        architecture_cn: '',
        architecture_us: '',
        product_recommend: [],
        company_case: [],
        document: {
          banner: {
            image_path: '',
          },
          generalize: {
            title_cn: '',
            title_us: '',
            describe_cn: '',
            describe_us: '',
            route: '',
            router_name_cn: '',
            router_name_us: ''
          },
          merit: [],
          scheme: [],
          resource: [],
          follow_up: []
        },
        route: '',
      },
      rules: {
          type: [
            { required: true, message: '请输选择类型', trigger: 'blur' },
          ],
          title_cn: [
            { required: true, message: '请输入标题(中文)', trigger: 'blur' },
            { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
          ],
          title_us: [
            { required: true, message: '请输入标题(英文)', trigger: 'blur' },
            { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
          ],
          cover_path: [
            { required: true, message: '请上传封面', trigger: 'blur' },
          ],
          route: [
            { required: true, message: '请选择路由模板', trigger: 'blur' },
          ]
      },
      itemRules: {
        image_path: [
          { required: true, message: '请上传banner图', trigger: 'blur' },
        ],
        cover_path: [
          { required: true, message: '请上传封面', trigger: 'blur' },
        ],
        title_cn: [
          { required: true, message: '请输入标题(中文)', trigger: 'blur' },
        ],
        title_us: [
          { required: true, message: '请输入标题(英文)', trigger: 'blur' },
        ],
      }
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  methods: {
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.ruleForm = {
        id: null,
        type: 1,
        title_cn: '',
        title_us: '',
        subhead_cn: '',
        subhead_us: '',
        cover_path: '',
        introduce_cn: '',
        introduce_us: '',
        architecture_cn: '',
        architecture_us: '',
        product_recommend: [],
        company_case: [],
        document: {
          banner: {
            image_path: '',
          },
          generalize: {
            title_cn: '',
            title_us: '',
            describe_cn: '',
            describe_us: '',
            route: '',
            router_name_cn: '',
            router_name_us: ''
          },
          merit: [],
          scheme: [],
          resource: [],
          follow_up: []
        },
        route: '',
      }
      this.$emit('close')
    },
    addMeritForm () {
      this.ruleForm.document.merit.push({
        title_cn: '',
        title_us: '',
        describe_cn: '',
        describe_us: '',
        route: '',
        router_name_cn: '',
        router_name_us: ''
      })
    },
    deleteMeritForm(index) {
      this.ruleForm.document.merit.splice(index, 1)
    },
    deleteResourceForm (index) {
      this.ruleForm.document.resource.splice(index, 1)
    },
    addResourceForm () {
      this.ruleForm.document.resource.push({
        title_cn: '',
        title_us: '',
        describe_cn: '',
        describe_us: '',
        route: '',
        router_name_cn: '',
        router_name_us: ''
      })
    },
    deleteFollowUpForm (index) {
      this.ruleForm.document.follow_up.splice(index, 1)
    },
    addFollowUpForm () {
      this.ruleForm.document.follow_up.push({
        title_cn: '',
        title_us: '',
        describe_cn: '',
        describe_us: '',
        route: '',
        router_name_cn: '',
        router_name_us: ''
      })
    },
    addSchemeForm () {
      this.ruleForm.document.scheme.push({
        title_cn: '',
        title_us: '',
        children: [
          {
            title_cn: '',
            title_us: '',
            cover_path: '',
            describe_cn: '',
            describe_us: '',
            route: '',
            router_name_cn: '',
            router_name_us: ''
          }
        ]
      })
    },
    deleteSchemeForm (index) {
      this.ruleForm.document.scheme.splice(index, 1)
    },
    addSchemeItemForm (index) {
      this.ruleForm.document.scheme[index].children.push({
        title_cn: '',
        title_us: '',
        cover_path: '',
        describe_cn: '',
        describe_us: '',
        route: '',
        router_name_cn: '',
        router_name_us: ''
      })
    },
    deleteSchemeItemForm (index1, index2) {
      this.ruleForm.document.scheme[index1].children.splice(index2, 1)
    },
    openDialog () {
      if (this.params.data) {
        this.ruleForm = this.params.data
      }
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) {
          this.$message.error('请检查是否完成所有必填项！')
          return
        }
        const result = this.params.data ? await editSolution(this.ruleForm) : await addSolution(this.ruleForm)
        if (result.success) {
          this.$message.success('成功')
          this.close()
        }
      })
    },
  },
  computed: {
    
  },
}
</script>
<style scoped lang="less">
  .el-link {
    margin-bottom: 10px;
    margin-top: 8px;
    margin-left: 10px;
  }
</style>
