<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>解决方案管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row>
      <el-col :span="24">
        <el-select @change="selectChange" v-model="queryInfo.type" placeholder="请选择">
          <el-option
            v-for="item in typeValue"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="addClick">添加</el-button>
      </el-col>
    </el-row>

    <table-ui
      :propList="propList"
      :operationProps="operationProps"
      :listData="solutionList"
      v-bind="otherProps"
      :listCount="solutionTotal"
      v-model="queryInfo"
      @page="pageChange"
    >
      <template #typeSlot="scope">{{ scope.row.type | solutionTypeFormat }}</template>
      <template #coverSlot="scope">
        <el-image slot="error" :src="scope.row.cover_path" >
          <i class="el-icon-picture-outline"></i>
        </el-image>
      </template>
      <template #operation="scope">
        <span @click="lookIndustryClick(scope.row)" style="margin-right: 10px;cursor: pointer;">查看案例</span>
        <span @click="lookProductClick(scope.row)" style="margin-right: 10px;cursor: pointer;">查看产品</span>
        <span @click="editClick(scope.row)" style="margin-right: 10px;cursor: pointer;">编辑</span>
        <el-popconfirm title="确定删除？" @confirm="deleteClick(scope.row.id)">
          <span slot="reference" style="color: red;cursor: pointer;">删除</span>
        </el-popconfirm>
      </template>
    </table-ui>
    <add-edit-solution-dialog :params="addEditSolutionInfo" @close="addEditSolutionDialogClose"></add-edit-solution-dialog>
    <industry-list :params="industryRouteInfo" @refresh="industryRefresh" @close="industryClose"></industry-list>
    <product-list :params="productRouteInfo" @refresh="productRefresh" @close="productClose"></product-list>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TableUi from '@/components/table-ui/table-ui.vue'
import { propList, operationProps, otherProps } from './config/solutionConfig.js'
import { solutionDetail, deleteSolution } from '@/api/solution/solution'
import addEditSolutionDialog from './cnps/add-edit-solution-dialog.vue'
import industryList from './cnps/industry-list.vue'
import productList from './cnps/product-list.vue'
export default {
  data() {
    return {
      propList,
      operationProps,
      otherProps,
      typeValue: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '混合云解决方案',
          value: 1
        },
        {
          label: '网站解决方案',
          value: 2
        },
        {
          label: '行业解决方案',
          value: 3
        },
        {
          label: '工程解决方案',
          value: 4
        }
      ],
      queryInfo: {
        cur: 1,
        size: 20,
        type: 0,
      },
      addEditSolutionInfo: {
        show: false,
        data: null,
      },
      industryRouteInfo: {
        show: false,
        id: null,
        view: [],
      },
      productRouteInfo: {
        show: false,
        id: null,
        view: [],
      }
    }
  },
  components: {
    TableUi,
    addEditSolutionDialog,
    industryList,
    productList,
  },
  mounted() {
    this.getDataList()
  },
  computed: {
    ...mapState('solution', ['solutionList', 'solutionTotal'])
  },
  methods: {
    ...mapActions('solution', ['getSolutionList']),
    getDataList () {
      this.getSolutionList(this.queryInfo)
    },
    pageChange(ev) {
      this.queryInfo.cur = ev.cur
      this.queryInfo.size = ev.size
      this.getDataList()
    },
    selectChange() {
      this.queryInfo.cur = 1
      this.getDataList()
    },
    // 增加
    addClick() {
      this.addEditSolutionInfo.show = true
    },
    // 编辑
    async editClick(row) {
      const details = await solutionDetail(row.id)
      if (!details.success) return
      this.addEditSolutionInfo.data = {
          id: row.id,
          type: row.type,
          title_cn: row.title_cn,
          title_us: row.title_us,
          subhead_cn: row.subhead_cn || '',
          subhead_us: row.subhead_us || '',
          cover_path: row.cover_path,
          introduce_cn: details.data.introduce_cn || '',
          introduce_us: details.data.introduce_us || '',
          architecture_cn: details.data.architecture_cn || '',
          architecture_us: details.data.architecture_us || '',
          product_recommend: details.data.product_recommend,
          company_case: details.data.company_case,
          route: details.data.route,
          document: details.data.document,
        }
      this.addEditSolutionInfo.show = true
    },
    // 关闭窗口
    addEditSolutionDialogClose () {
      this.addEditSolutionInfo.show = false;
      this.addEditSolutionInfo.data = null;
      this.getDataList()
    },
    // 删除
    async deleteClick(id) {
      const result = await deleteSolution(id)
      if (result.success) {
        this.$message.success('删除成功')
        this.getDataList()
      }
    },
    async lookIndustryClick (row) {
      const details = await solutionDetail(row.id)
      if (!details.success) return
      this.industryRouteInfo.view = details.data.company_case_list;
      this.industryRouteInfo.id = row.id;
      this.industryRouteInfo.show = true;
    },
    async industryRefresh () {
      const details = await solutionDetail(this.industryRouteInfo.id)
      if (!details.success) return
      this.industryRouteInfo.view = details.data.company_case_list;
    },
    industryClose () {
      this.industryRouteInfo.show = false;
      this.industryRouteInfo.view = [];
      this.industryRouteInfo.id = null;
      this.getDataList()
    },
    async lookProductClick (row) {
      const details = await solutionDetail(row.id)
      if (!details.success) return
      this.productRouteInfo.view = details.data.product_recommend_list;
      this.productRouteInfo.id = row.id;
      this.productRouteInfo.show = true;
    },
    async productRefresh () {
      const details = await solutionDetail(this.productRouteInfo.id)
      if (!details.success) return
      this.productRouteInfo.view = details.data.product_recommend_list;
    },
    productClose () {
      this.productRouteInfo.show = false;
      this.productRouteInfo.view = [];
      this.productRouteInfo.id = null;
      this.getDataList()
    },
  },
  
}
</script>

<style lang="less" scoped>
  .el-button {
    margin-left: 10px;
  }
</style>